// DON'T FORMAT ON SAVE. TRAILING SPACES ARE BEING USED FOR LINE BREAKS

export const mapMovement = {
  order: 2,
  name: "Map movement",
  keywords: "zoom pan scroll",
  subcategory: "The map",
  markdown: `# Map movement
  Interacting with the map should feel familiar to anyone who has used other web-based maps. Below are a few of the key interactions.

  {% callout type="tip" %}
  **Tip:** The interactions below are best for exploring at the local level. For big location changes, it is much faster to use {% inlineRouterLink %}search{% /inlineRouterLink %}.
  {% /callout %}

  ## Drag panning with inertia
  Click and drag the mouse on the map surface to pan the map. Release the left mouse button to stop moving.  

  Panning of the map will have an inertia effect where the map builds momentum while dragging and continues moving in the same direction for some time.

  ## Map zoom control
  Click the **+** and **-** buttons in the lower-right corner of the map to zoom in and out. This will zoom to the center of the view.

  ## Scroll wheel zoom
  Use the scroll gesture on your mouse or trackpad to zoom in and out. This will zoom to the location of your mouse pointer.

  ## Double-click zoom
  Zoom in toward the location of your mouse pointer by double-clicking.

  ## Zoom to area (shift-drag)
  While holding down {% inlineKeyboardShortcut %}Shift{% /inlineKeyboardShortcut %}, click and drag a box around an area you want to zoom to. Release the left mouse button to zoom all the way in to the boundaries of the box.

  ![Zoom to area GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)

  ## Keyboard navigation

  Pan with {% inlineKeyboardShortcut %}&larr;{% /inlineKeyboardShortcut %}, {% inlineKeyboardShortcut %}&uarr;{% /inlineKeyboardShortcut %}, {% inlineKeyboardShortcut %}&rarr;{% /inlineKeyboardShortcut %}, and {% inlineKeyboardShortcut %}&darr;{% /inlineKeyboardShortcut %}. Zoom with {% inlineKeyboardShortcut %}+{% /inlineKeyboardShortcut %} and {% inlineKeyboardShortcut %}-{% /inlineKeyboardShortcut %}.

  {% callout type="caution" %}
  **Wait!** Keyboard navigation only works when the map is selected. Click somewhere on the map surface to select it.
  {% /callout %}`,
};
